import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/codebuild/output/src2221987962/src/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2221987962/src/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2221987962/src/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\",\"preload\":true}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2221987962/src/build.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2221987962/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/codebuild/output/src2221987962/src/provider/auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppStateProvider"] */ "/codebuild/output/src2221987962/src/provider/options-provider.tsx");
